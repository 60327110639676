<template>
  <form @submit.prevent="invio">
    <div>
      {{ $gettext('Per modificare la tua password è necessario inserire il codice di controllo che ti invieremo via email.') }}
    </div>
    <custom-input
      :placeholder='$gettext("Indirizzo email")'
      label="Email"
      :value="email"
      type="email"
      @input="(val) => (email = val)"
    />
    <div id="button">
      <button :disabled="!sendOk" v-if="!loading">{{ $gettext('Richiedi codice') }}</button>
    </div>
  </form>
  <div class="loading" v-if="loading">
    {{ $gettext('Controllo email ed invio codice in corso...') }}
  </div>
  <div id="errore" v-if="error != ''" v-html="errorMsg"></div>
</template>

<script>
import customInput from '@/components/shared/custom-input';
export default {
  name: 'lost_password_step1',
  components: { customInput },
  data() {
    return {
      email: '',
      loading: false,
      errorMsg: '',
    };
  },
  methods: {
    async invio() {
      this.loading = true;

      try {
        let response = await this.axios.post(this.api.reset_step_1, {
          email: this.email,
        });
      } catch (error) {
        this.errorMsg = $gettext('Impossibile richiedere codice di verifica');
        this.loading = false;
        return;
      }
      //Qui la chiamata all'invio del codice

      this.loading = false;
      this.$router.push({ name: 'Lost password step 2' });
    },
  },
  computed: {
    sendOk() {
      if (this.email.trim() !== '') return true;
      return false;
    },
  },
};
</script>

<style scoped>
form {
  width: 90%;
  margin: 30px auto 0;
}
</style>
